import { Directive, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { CheckboxComponent } from '@util/components/checkbox/checkbox.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';


@Directive({
  selector: '[appCheckedValidator]',
  inputs: ['appCheckedValidator.useTranslationKey'],
  exportAs: 'appCheckedValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => CheckedValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => CheckboxComponent)}
  ]
})
export class CheckedValidatorDirective extends BaseValidatorDirective implements OnInit, OnDestroy {

  protected _thisSelector = 'appCheckedValidator';
  protected override translationKey = 'validation.checkbox.appCheckedValidator';

  override ngOnInit() {
    super.ngOnInit();

    if (this.host?.formControl) {

      this.host.formControl.addValidators(this.validator);
      this.host.formControl.updateValueAndValidity();
      this.host.formControl.markAsPristine();
      this.host.formControl.markAsUntouched();

    }
  }

  // ngOnDestroy() {super.ngOnDestroy();}

  validator: ValidatorFn = (control) => {
    const errors: ValidationErrors = {'appCheckedValidator': this.translation};
    return control?.value ? null : errors;
  };

}
