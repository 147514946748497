import { Directive, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';
import { TextareaComponent } from '@util/components/textarea/textarea.component';


@Directive({
  selector: '[appTextareaRequiredValidator]',
  inputs: ['appTextareaRequiredValidator.useTranslationKey'],
  exportAs: 'appTextareaRequiredValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => TextareaRequiredValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => TextareaComponent)}
  ]
})
export class TextareaRequiredValidatorDirective extends BaseValidatorDirective implements OnInit, OnDestroy {

  protected _thisSelector = 'appTextareaRequiredValidator';
  protected override translationKey = 'validation.textarea.appTextareaRequiredValidator';

  override ngOnInit() {
    super.ngOnInit();

    if (this.host) {
      this.host.hasRequiredValidator = true;
    }

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.host) {
      this.host.hasRequiredValidator = false;
    }
  }

  validator: ValidatorFn = (control) => {
    const errors: ValidationErrors = {'appTextareaRequiredValidator': this.translation};
    return control?.value ? null : errors;
  };

}
