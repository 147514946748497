import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { CheckedValidatorDirective } from './checkbox-validator/checked-validator.directive';
import { InputLateDateValidatorDirective } from './input-validators/input-late-date-validator.directive';
import { InputRequiredValidatorDirective } from './input-validators/input-required-validator.directive';
import { InputMaxLengthValidatorDirective } from './input-validators/input-max-length-validator.directive';
import { InputMinLengthValidatorDirective } from './input-validators/input-min-length-validator.directive';
import { InputSameAsValidatorDirective } from './input-validators/input-same-as-validator.directive';
import { InputRegExpCharacterValidatorValidatorDirective } from './input-validators/input-regexp-character-validator.directive';
import { InputEmailValidatorDirective } from './input-validators/input-email-validator.directive';
import { TextareaRequiredValidatorDirective } from './textarea-validators/textarea-required-validator.directive';
import { InputWithinMinMaxValidatorDirective } from './input-validators/input-within-min-max-validator.directive';
import { InputForbiddenValidatorDirective } from './input-validators/input-forbidden-validator.directive';

@NgModule({
  declarations: [
    InputRequiredValidatorDirective,
    InputForbiddenValidatorDirective,
    InputLateDateValidatorDirective,
    InputMaxLengthValidatorDirective,
    InputMinLengthValidatorDirective,
    InputSameAsValidatorDirective,
    InputRegExpCharacterValidatorValidatorDirective,
    InputEmailValidatorDirective,
    InputWithinMinMaxValidatorDirective,
    CheckedValidatorDirective,
    TextareaRequiredValidatorDirective
  ],
  imports: [
    CommonModule,
    I18nModule
  ],
  exports: [
    InputRequiredValidatorDirective,
    InputForbiddenValidatorDirective,
    InputLateDateValidatorDirective,
    InputMaxLengthValidatorDirective,
    InputMinLengthValidatorDirective,
    InputSameAsValidatorDirective,
    InputRegExpCharacterValidatorValidatorDirective,
    InputEmailValidatorDirective,
    InputWithinMinMaxValidatorDirective,
    CheckedValidatorDirective,
    TextareaRequiredValidatorDirective
  ]
})
export class BaseFormValidatorsModule { }

